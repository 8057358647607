import t from 'react-translate';
import { useState } from 'react';
import { css } from '@emotion/react';
import { config } from '@config/pendo.config.json';
import NvPopover from 'shared/components/nv-popover';
import NvSwitch from 'shared/components/inputs/nv-switch';
import NvConfirmationPopover from 'shared/components/nv-confirmation-popover';
import { useFormContext } from 'react-hook-form';
import { gray6 } from 'styles/global_defaults/colors';

const overlayStyles = css`
  max-width: 280px;
  .confirmation {
    max-width: 100%;
    .button-container {
      justify-content: center;
    }
  }
`;

const styles = css`
  &.disabled {
    .switch-container {
      background-color: ${gray6};
    }
  }
`;

type RequiredSwitchProps = {
  isRequired: boolean;
  setIsRequired: (isRequired: boolean) => void;
  isAttachedToRule: boolean;
  disabled: boolean;
};

const RequiredSwitch = ({ isRequired, setIsRequired, isAttachedToRule, disabled }: RequiredSwitchProps) => {
  const [isSwitchingRequired, setIsSwitchingRequired] = useState(false);
  const { setValue } = useFormContext();

  const hardSetIsRequired = (value: any) => {
    setIsSwitchingRequired(value);
    setIsRequired(value);
  };

  const handleRequiredChange = (newState: boolean) => {
    setValue('isRequired', newState, {
      shouldValidate: true,
      shouldDirty: true,
    });
    if (!newState && isAttachedToRule) hardSetIsRequired(true);
    else setIsRequired(newState);
  };

  return (
    <NvPopover
      enabled
      show={isSwitchingRequired}
      overlayStyles={overlayStyles}
      content={(
        <NvConfirmationPopover
          onCancel={() => setIsSwitchingRequired(false)}
          onConfirm={() => hardSetIsRequired(false)}
          header={t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_PROFILE_SETUP.PROGRAM_LEVEL_FIELDS.REQUIRED_POPOVER.TITLE()}
          message={t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_PROFILE_SETUP.PROGRAM_LEVEL_FIELDS.REQUIRED_POPOVER.DESCRIPTION()}
          cancelBtnText={t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_PROFILE_SETUP.PROGRAM_LEVEL_FIELDS.REQUIRED_POPOVER.CANCEL()}
          confirmBtnText={t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_PROFILE_SETUP.PROGRAM_LEVEL_FIELDS.REQUIRED_POPOVER.OK()}
        />
      )}
      placement='top'
      rootClose
      onHide={() => setIsSwitchingRequired(false)}
      className='d-inline-flex mw-100'
      preventOverflow
    >
      <NvSwitch
        withForm
        css={styles}
        disabled={disabled}
        name='isRequired'
        checked={isRequired}
        className={`self-centered align-content-center ${disabled ? 'disabled' : ''}`}
        onChange={handleRequiredChange}
        data-qa={
          config.pendo.athena.mentorshipProgram.settings.programProfileSetup
            .updateProgramLevelProfileQuestion
        }
      />
    </NvPopover>
  );
};

export default RequiredSwitch;
